<template>
    <b-modal
        :id="$options.name"
        :cancel-title="$t('form.cancel')"
        :ok-title="$t('form.save')"
        :title="title"
        header-bg-variant="primary"
        scrollable
        size="xl"
        @ok="formSave"
    >
        <b-overlay v-if="record !== null" :opacity="overlay.opacity" :show="overlay.visible" rounded="sm">
            <b-form ref="form">
                <!-- id, active -->
                <form-id-active :record.sync="record"></form-id-active>
                <!-- left tabs -->
                <b-card no-body>
                    <b-tabs v-model="tabIndex" card pills vertical>
                        <!-- tab basic -->
                        <b-tab :active="record.id > 0" active>
                            <template #title>
                                <b-icon v-if="tabBasicValidity === false"
                                        class="mb-1"
                                        font-scale="0.7"
                                        icon="exclamation-circle-fill"
                                        variant="danger">
                                </b-icon>
                                {{$t('form.tab.basic')}}
                            </template>
                            <b-card-text>
                                <!-- meta -->
                                <b-row>
                                    <!-- meta label -->
                                    <b-col cols="12">
                                        <b-form-group
                                            :label="$tc('form.meta.data', 0)"
                                            class="mb-0"
                                            label-class="font-weight-bold pt-0"
                                            label-size="md"
                                        >
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col cols="12" lg="6">
                                        <!-- meta name -->
                                        <b-form-group
                                            :label="$t('adventure.title') + '*'"
                                            class="mb-2 mr-sm-2 mb-sm-0"
                                            description=""
                                            label-class="mb-0"
                                            label-for="meta_name"
                                        >
                                            <b-form-input
                                                id="meta_name"
                                                v-model="record.meta.name"
                                                :state="nameValidity"
                                                class="mb-2 mr-sm-2 mb-sm-0"
                                                type="text"
                                            ></b-form-input>
                                            <b-form-invalid-feedback :state="nameValidity">
                                                {{$t('form.required_field')}}
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                        <!-- meta subtitle -->
                                        <b-form-group
                                            class="mb-2 mr-sm-2 mb-sm-0"
                                            label=""
                                            label-class="font-weight-bold pt-0"
                                            label-size="md"
                                        >
                                        </b-form-group>
                                    </b-col>
                                    <b-col cols="12" lg="6">
                                        <b-form-group
                                            :label="$t('form.subtitle')"
                                            class="mb-2 mr-sm-2 mb-sm-0"
                                            description=""
                                            label-class="mb-0"
                                            label-for="meta_subtitle"
                                        >
                                            <b-form-input
                                                id="meta_subtitle"
                                                v-model="record.meta.subtitle"
                                                class="mb-2 mr-sm-2 mb-sm-0"
                                                type="text"
                                            ></b-form-input>
                                        </b-form-group>
                                        <!-- basic data -->
                                        <b-form-group
                                            class="mb-2"
                                            label=""
                                            label-class="font-weight-bold pt-0"
                                            label-size="md"
                                        >
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <!-- content -->
                                <b-row>
                                    <!-- label content -->
                                    <b-col cols="12">
                                        <b-form-group
                                            :label="$t('form.content')"
                                            class="mb-0"
                                            label-class="font-weight-bold pt-0"
                                            label-size="md"
                                        >
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <!-- OwnershipTypes -->
                                    <b-col cols="6">
                                        <b-form-group
                                            class="mb-2 mr-sm-2 mb-sm-0"
                                            label-class="mb-0"
                                            :label="$t('adventure.ownership_type') + '*'"
                                            label-for="languageId"
                                            description=""
                                        >
                                            <b-form-select
                                                id="languageId"
                                                v-model="record.ownership_type_id"
                                                :options="getOwnershipTypes"
                                                required
                                                :state="ownershipTypeIdValidity"
                                                @change="changeOwnershipType()"
                                            >
                                            </b-form-select>
                                            <b-form-invalid-feedback :state="ownershipTypeIdValidity">
                                                {{$t('form.required_field')}}
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                    </b-col>
                                    <!-- web page -->
                                    <b-col cols="6">
                                        <b-form-group
                                            :label="$t('organisation.webpage')"
                                            class="mb-2 mr-sm-2 mb-sm-0"
                                            label-class=" mb-0"
                                            label-for="organisation_url"
                                            label-size="md"
                                        >
                                            <b-input-group>
                                                <b-form-input
                                                    id="organisation_url"
                                                    ref="ref-webpage"
                                                    v-model="record.extended.webpage"
                                                    :placeholder="$t('organisation.webpage_placeholder')"
                                                    :state="urlValidity"
                                                    :tooltip="$t('organisation.webpage_tooltip')"
                                                    class="mb-2 mr-sm-0 mb-sm-0"
                                                    type="url"
                                                ></b-form-input>
                                                <b-input-group-append>
                                                    <b-button
                                                        :disabled="record.extended.webpage === null || record.extended.webpage.length < 4"
                                                        :href="record.extended.webpage"
                                                        target="_blank"
                                                        variant="info"
                                                    >
                                                        <b-icon icon="link45deg"></b-icon>
                                                    </b-button>
                                                </b-input-group-append>
                                                <b-form-invalid-feedback :state="urlValidity">
                                                    {{$t('form.url_validity_message')}}
                                                </b-form-invalid-feedback>
                                            </b-input-group>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row class="mt-2">
                                    <!-- owner or manager -->
                                    <b-col cols="6">
                                        <!-- owner id -->
                                        <b-form-group
                                            class="mb-2 mr-sm-2 mb-sm-0"
                                            label-class="mb-0"
                                            :label="$t('adventure.owner_manager') + '*'"
                                            label-for="ownerId"
                                            description=""
                                        >
                                            <b-form-select
                                                id="ownerId"
                                                v-model="record.organisation_id"
                                                :options="getOrganisations"
                                                required
                                                :disabled="!getOwnerVisible.visible"
                                                :state="organisationIdValidity"
                                            ></b-form-select>
                                            <b-form-invalid-feedback :state="organisationIdValidity">
                                                {{$t('form.required_field')}}
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                    </b-col>
                                    <!-- order -->
                                    <b-col cols="6">
                                        <!-- order -->
                                        <b-form-group
                                            id="group-order"
                                            class="mb-2 mr-sm-02 mb-sm-0"
                                            label-class="mb-0"
                                            :label="$t('form.order')"
                                            label-for="order"
                                            description=""
                                        >
                                            <b-form-input
                                                id="order"
                                                type="number"
                                                v-model="record.order"
                                            ></b-form-input>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row class="mt-2">
                                    <b-col cols="6">
                                        <!-- languages tour -->
                                        <b-form-group
                                            class="mb-2 mr-sm-2 mb-sm-0"
                                            label-class="mb-0"
                                            :label="$tc('form.language', 0) + '*'"
                                            label-for="languageId"
                                            description=""
                                            :state="languageTourIdsValidity"
                                        >
                                            <b-form-select
                                                id="languageId"
                                                v-model="languageId"
                                                :options="getLanguages"
                                                required
                                                :state="languageTourIdsValidity"
                                            >
                                            </b-form-select>
                                            <b-form-invalid-feedback :state="languageTourIdsValidity">
                                                {{$t('form.required_field')}}
                                            </b-form-invalid-feedback>
                                            <!-- selected languageTour -->
                                            <div class="mt-1">
                                                <b-button
                                                    v-for="language in getLanguageTourIdsSelected"
                                                    pill
                                                    class="mr-1 mt-1 remove"
                                                    variant="primary"
                                                    :title="$t('form.remove')"
                                                    size="sm"
                                                    @click="removeSelectedLanguage(language.value)"
                                                >{{language.text}}
                                                </b-button>
                                            </div>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                            </b-card-text>
                            <!-- basic locale -->
                            <form-destination-locale-basic
                                :title="$t('form.basic.data')"
                                :data_locale="data.locale"
                                :record="record.locale"
                            ></form-destination-locale-basic>
                        </b-tab>
                        <!-- tab location -->
                        <b-tab>
                            <template #title>
                                <b-icon v-if="tabLocationValidity === false"
                                        class="mb-1"
                                        font-scale="0.7"
                                        icon="exclamation-circle-fill"
                                        variant="danger">
                                </b-icon>
                                {{$t('adventure.destination.tab.location')}}
                            </template>
                            <!-- ownership is not selected -->
                            <b-card-text v-if="record.ownership_type_id === null">
                                <b-card class="bg-info __help_message">
                                    <div
                                        v-html="$t('adventure.ownership_type_not_selected', {tab: $t('form.tab.basic')})"></div>
                                </b-card>
                            </b-card-text>
                            <!--  -->
                            <b-card-text v-else>
                                <b-row>
                                    <b-col cols="6">
                                        <!--coordinates label-->
                                        <b-form-group
                                            :label="$t('adventure.coordinate.main')"
                                            class="mb-0"
                                            label-class="font-weight-bold pt-0"
                                            label-size="md"
                                        >
                                        </b-form-group>
                                    </b-col>
                                    <!-- other addresses -->
                                    <b-col cols="6">
                                        <!-- other -->
                                        <b-form-group
                                            :label="$t('organisation.address')"
                                            class="mb-0"
                                            label-class="font-weight-bold pt-0"
                                            label-size="md"
                                        >
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <!-- coordinates -->
                                    <b-col cols="6">
                                        <b-row>
                                            <!-- latitude -->
                                            <b-col cols="5" class="pl-3 pr-0">
                                                <b-form-group
                                                    :label="$t('adventure.coordinate.latitude')"
                                                    class="mb-2 mr-sm-2 mb-sm-0"
                                                    description=""
                                                    label-class="mb-0"
                                                    label-for="content-available"
                                                >
                                                    <b-form-input
                                                        v-model="record.content.coordinates.latitude"
                                                        type="number"
                                                        @keypress="__onlyDecimalNegKey($event)"
                                                        :state="latitudeValidity"
                                                    ></b-form-input>
                                                    <b-form-invalid-feedback :state="latitudeValidity">
                                                        {{$t('form.required_field')}}
                                                    </b-form-invalid-feedback>
                                                </b-form-group>
                                            </b-col>
                                            <!-- longitude -->
                                            <b-col cols="5" class=" pl-3 pr-0">
                                                <b-form-group
                                                    :label="$t('adventure.coordinate.longitude')"
                                                    class="mb-2 mr-sm-2 mb-sm-0"
                                                    description=""
                                                    label-class="mb-0"
                                                    label-for="content-available"
                                                >
                                                    <b-form-input
                                                        v-model="record.content.coordinates.longitude"
                                                        type="number"
                                                        @keypress="__onlyDecimalNegKey($event)"
                                                        :state="longitudeValidity"
                                                    ></b-form-input>
                                                    <b-form-invalid-feedback :state="longitudeValidity">
                                                        {{$t('form.required_field')}}
                                                    </b-form-invalid-feedback>
                                                </b-form-group>
                                            </b-col>
                                            <!-- button: paste from clipboard -->
                                            <b-col cols="1" class="d-flex align-items-end pl-0 pr-0">
                                                <b-form-group
                                                    label=""
                                                    class="mb-2 mr-sm-2 mb-sm-0"
                                                    description=""
                                                    label-class="mb-0"
                                                    label-for="content-available"
                                                >
                                                    <b-button size="small" @click="pasteCoordinatesFromClipboard()">
                                                        <b-icon-clipboard scale="1.1"></b-icon-clipboard>
                                                    </b-button>
                                                </b-form-group>
                                            </b-col>
                                        </b-row>
                                    </b-col>
                                    <b-col cols="6">
                                        <!--address-->
                                        <b-form-checkbox
                                            v-if="record.ownership_type_id === ownershipTypeIdOrganisation"
                                            id="checkbox-alcohol"
                                            name="checkbox--alcohol"
                                            v-model="record.content.useOrganisationAddress"
                                            class="mb-2"
                                        >
                                            {{$t('adventure.use_organisation_address')}}
                                        </b-form-checkbox>
                                        <form-address
                                            v-if="!record.content.useOrganisationAddress || record.ownership_type_id === ownershipTypeIdPublic"
                                            :data="data.states"
                                            :record.sync="getAddress"
                                        ></form-address>
                                    </b-col>
                                </b-row>
                            </b-card-text>
                        </b-tab>
                        <!-- contacts -->
                        <b-tab :title="$t('organisation.tab.contact')">
                            <!-- ownership is not selected -->
                            <b-card-text v-if="record.ownership_type_id === null">
                                <b-card class="bg-info __help_message">
                                    <div
                                        v-html="$t('adventure.ownership_type_not_selected', {tab: $t('form.tab.basic')})"></div>
                                </b-card>
                            </b-card-text>
                            <!--  -->
                            <b-card-text v-else>
                                <!-- phones -->
                                <b-form-group
                                    :label="$t('organisation.phone')"
                                    class="mb-2 mr-sm-2 mb-sm-0 pt-0"
                                    description=""
                                    label-class="mb-0"
                                    label-for="data.phone"
                                >
                                    <b-form-checkbox
                                        v-if="record.ownership_type_id === ownershipTypeIdOrganisation"
                                        id="checkbox-useCompanyPhones"
                                        v-model="record.content.useOrganisationPhone"
                                        class="mb-2"
                                    >
                                        {{$t('adventure.use_organisation_phone')}}
                                    </b-form-checkbox>
                                    <form-table-values
                                        v-if="!record.content.useOrganisationPhone || record.ownership_type_id === ownershipTypeIdPublic"
                                        id="data.phone"
                                        :column_a_text="$t('form.table_values.person')"
                                        :column_b_text="$t('form.table_values.description')"
                                        :column_c_text="$t('form.table_values.number')"
                                        :extended="true"
                                        :record="recordClone.contact"
                                        input_c_type="tel"
                                        json_key="phone"
                                        @eventUpdateRecord="contactUpdate"
                                    />
                                </b-form-group>
                                <!-- email -->
                                <b-form-group
                                    :label="$t('organisation.email')"
                                    class="mb-2 mr-sm-2 mb-sm-0 pt-2"
                                    description=""
                                    label-class="mb-0"
                                    label-for="data.email"
                                >
                                    <b-form-checkbox
                                        v-if="record.ownership_type_id === ownershipTypeIdOrganisation"
                                        id="checkbox-useOrganisationEmail"
                                        v-model="record.content.useOrganisationEmail"
                                        class="mb-2"
                                    >
                                        {{$t('adventure.use_organisation_email')}}
                                    </b-form-checkbox>
                                    <form-table-values
                                        v-if="!record.content.useOrganisationEmail || record.ownership_type_id === ownershipTypeIdPublic"
                                        id="data.email"
                                        :column_a_text="$t('form.table_values.person')"
                                        :column_b_text="$t('form.table_values.description')"
                                        :column_c_text="$t('form.table_values.address')"
                                        :extended="true"
                                        :record="recordClone.contact"
                                        input_c_type="email"
                                        json_key="email"
                                        @eventUpdateRecord="contactUpdate"
                                    />
                                </b-form-group>
                                <!-- internet contacts -->
                                <b-form-group
                                    :label="$t('organisation.internet_contact')"
                                    class="mb-2 mr-sm-2 mb-sm-0 pt-2"
                                    description=""
                                    label-class="mb-0"
                                    label-for="data.internetContact"
                                >
                                    <b-form-checkbox
                                        v-if="record.ownership_type_id === ownershipTypeIdOrganisation"
                                        id="checkbox-useInternetContact"
                                        v-model="record.content.useOrganisationInternetContact"
                                        class="mb-2"
                                    >
                                        {{$t('adventure.use_organisation_internet_contact')}}
                                    </b-form-checkbox>
                                    <form-table-values
                                        v-if="!record.content.useOrganisationInternetContact  || record.ownership_type_id === ownershipTypeIdPublic"
                                        id="data.internetContact"
                                        :column_a_text="$t('form.table_values.person')"
                                        :column_b_text="$t('form.table_values.description')"
                                        :column_c_text="$t('form.table_values.address')"
                                        :extended="true"
                                        :record="recordClone.contact"
                                        input_c_type="text"
                                        json_key="internet_contact"
                                        @eventUpdateRecord="contactUpdate"
                                    />
                                </b-form-group>
                            </b-card-text>
                        </b-tab>
                        <!-- description -->
                        <b-tab :title="$t('adventure.destination.tab.description')">
                            <b-card-text>
                                <b-form-group
                                    label=""
                                    class="mb-0 group-title p-0"
                                    label-align-sm="left"
                                    label-class="font-weight-bold"
                                    label-cols-sm="12"
                                    label-size=""
                                >
                                    <b-card no-body>
                                        <tab-locale-editor
                                            :data_locale="data.locale"
                                            :label="$t('adventure.destination.tab.description')"
                                            :record="record.locale"
                                            color_class="editor-yellow"
                                            json_key="description"
                                        ></tab-locale-editor>
                                    </b-card>
                                </b-form-group>
                            </b-card-text>
                        </b-tab>
                        <!-- tab host -->
                        <b-tab :title="$t('adventure.destination.tab.host')">
                            <b-card-text>
                                <b-form-group
                                    label=""
                                    class="mb-0 group-title p-0"
                                    label-align-sm="left"
                                    label-class="font-weight-bold"
                                    label-cols-sm="12"
                                    label-size=""
                                >
                                    <b-card no-body>
                                        <tab-locale-editor
                                            :data_locale="data.locale"
                                            :label="$t('adventure.host')"
                                            :record="record.locale"
                                            color_class="editor-green"
                                            json_key="host"
                                        ></tab-locale-editor>
                                    </b-card>
                                </b-form-group>
                            </b-card-text>
                        </b-tab>
                        <!-- pictures -->
                        <b-tab :title="$t('form.tab.picture')">
                            <PictureManagerDeprecated
                                :picture-manager-bus="pictureManagerBus"
                                @eventUpdate="eventPictureManagerUpdate"
                            ></PictureManagerDeprecated>
                        </b-tab>
                        <!-- notes -->
                        <b-tab :title="$t('form.tab.notes')">
                            <b-card-text>
                                <b-form-group
                                    label=""
                                    class="mb-0 group-title p-0"
                                    label-align-sm="left"
                                    label-class="font-weight-bold"
                                    label-cols-sm="12"
                                    label-size=""
                                >
                                    <b-card no-body>
                                        <tab-locale-editor
                                            :data_locale="data.locale"
                                            :label="$tc('form.notes', 2)"
                                            :record="record.locale"
                                            json_key="notes"
                                        ></tab-locale-editor>
                                    </b-card>
                                </b-form-group>
                            </b-card-text>
                        </b-tab>
                    </b-tabs>
                </b-card>
                <!--  -->
            </b-form>
        </b-overlay>
    </b-modal>
</template>

<script>
import Vue from "vue";
import FormIdActive from "@/components/general/elements/formElements/FormIdActive";
import TabLocaleEditor from "@/components/general/elements/tabAccessories/TabLocaleEditor";
import FormAddress from "@/components/general/elements/formElements/FormAddress";
import FormTableValues from "@/components/general/elements/formElements/FormTableValues";
import FormDestinationLocaleBasic from "@/components/adventure/destination/FormDestinationLocaleBasic";
import PictureManagerDeprecated
    from "@/components/general/elements/pictures/pictures_manager_deprecated/PictureManagerDeprecated";

//@group Form.ADVENTURE
export default {
    name: "ModalFormDestination",
    components: {
        PictureManagerDeprecated,
        FormDestinationLocaleBasic, FormTableValues, FormAddress, TabLocaleEditor, FormIdActive
    },
    props: {
        // Unique key to define modal window
        localeKey: {
            type: String,
            default: null,
            required: true
        },
        // Uri for calling api
        uri: {
            type: Object,
            default: null,
            required: false
        },
        // Bus object for events
        modalBus: {
            type: Object,
            default() {
                return {}
            },
            required: false
        },
    },
    data() {
        return {
            overlay: {
                visible: false,
                opacity: 0.7,
            },
            tabIndex: 0,
            data: {},
            division: 'destination',
            recordId: 0,
            recordDefault: {
                id: 0,
                ownership_type_id: null,
                organisation_id: null,
                user_id: null,
                address: {
                    state_id: null,
                    postOffice_id: null,
                    region_id: null,
                    street: null,
                    full: null
                },
                contact: {},
                meta: {
                    name: null,
                    subtitle: null,
                },
                content: {
                    useOrganisationAddress: true,
                    useOrganisationPhone: true,
                    useOrganisationEmail: true,
                    useOrganisationInternetContact: true,
                    coordinates: {
                        latitude: null,
                        longitude: null,
                    },
                    isGuidedTour: true,
                    languageTourIds: [],
                },
                locale: {},
                extended: {
                    liaison: null,
                    webpage: null,
                },
                // <-- pictures -->
                pictures_order: [],
                pictures: [],
                // <--  -->
                active: false,
                order: 0,
            },
            record: null,
            recordClone: {},
            // <-- validity -->
            validityDefault: {
                check: false,
            },
            tabValidity: {},
            // <-- errors -->
            errorsDefault: {
                message: null,
            },
            errors: {
                message: null
            },
            // <--  -->
            languageId: null,
            pictureManagerBus: new Vue(),
            // <-- ownershipId -->
            ownershipTypeIdPublic: 1,
            ownershipTypeIdOrganisation: 2,
            ownershipTypeIdUser: 2,
        }
    },
    mounted() {
        this.modalBus.$on('modalOpen', (recordId) => {
            this.recordId = recordId;
            this.modalOpen();
        });
    },
    methods: {
        showOverlay(show) {
            this.overlay.visible = show;
        },
        resetValidity() {
            this.$set(this, 'tabValidity', this.__clone(this.validityDefault));
        },
        resetErrors() {
            this.$set(this, 'errors', this.__clone(this.errorsDefault));
        },
        // <-- clipboard -->
        pasteCoordinatesFromClipboard() {
            navigator.clipboard.readText()
                .then((clipContent) => {
                    // <-- clipboard is null -->
                    if (clipContent === null) {
                        this.$bvModal.msgBoxOk(this.$t('leaflet.coordinates_unrecognizable_format')).then();
                        return;
                    }
                    // <--  -->
                    let isValidate = true;
                    let array = clipContent.split(',');
                    if (array.length !== 2) {
                        isValidate = false;
                    } else {
                        array.forEach((item, index) => {
                            array[index] = parseFloat(item);
                            if (typeof array[index] != 'number') {
                                isValidate = false;
                            }
                        });
                    }
                    // <-- messageBox: unrecognized format -->
                    if (!isValidate) {
                        this.$bvModal.msgBoxOk(this.$t('leaflet.coordinates_unrecognizable_format')).then();
                        return;
                    }
                    // <-- set coordinates -->
                    this.record.content.coordinates.latitude = array[0];
                    this.record.content.coordinates.longitude = array[1];
                });
        },
        // <-- modal -->
        modalOpen() {
            this.resetValidity();
            this.resetErrors();
            // <--  -->
            if (this.recordId > 0) {
                this.loadEdit();
                return;
            }
            this.loadNew();
        },
        modalClose() {
            this.showOverlay(false);
        },
        hideModal(data) {
            this.$emit('refresh', data);
            // <-- hide the modal manually -->
            this.$nextTick(() => {
                this.$bvModal.hide(this.$options.name);
            });
        },
        // <-- ownershipType -->
        changeOwnershipType() {
            let value = null;
            switch (this.record.ownership_type_id) {
                case 1:
                    value = false;
                    break;
                case 2:
                    value = true;
                    break;
            }
            // <--  -->
            this.record.content.useOrganisationAddress = value;
            this.record.content.useOrganisationEmail = value;
            this.record.content.useOrganisationInternetContact = value;
            this.record.content.useOrganisationPhone = value;
        },
        // <-- languages -->
        addSelectedLanguage(language) {
            this.record.content.isGuidedTour = true;
            if (language === null) {
                return;
            }
            // <-- no guided tour -->
            if (language === 0) {
                this.record.content.isGuidedTour = false,
                    this.record.content.languageTourIds = [];
                return;
            }
            // <--  -->
            let selectedLanguages = this.record.content.languageTourIds;
            // <-- check if exists -->
            let exists = false;
            selectedLanguages.every(v => {
                if (language !== v.id) {
                    return false;
                }
                exists = true;
                return true;
            });
            // <--  -->
            if (!exists) {
                this.record.content.languageTourIds.push(language);
            }
        },
        removeSelectedLanguage(languageId) {
            let selectedLanguages = this.record.content.languageTourIds;
            // <--  -->
            selectedLanguages.every((v, index) => {
                if (languageId === v) {
                    selectedLanguages.splice(index, 1);
                    return false;
                }
                return true;
            });
        },
        // <-- contacts -->
        contactUpdate(data) {
            this.$set(this.record.contact, data.key, data.values);
        },
        // <-- events pictureManager -->
        eventPictureManagerUpdate(data) {
            this.$set(this.record, 'pictures_order', data.pictures_order);
            this.$set(this.record, 'pictures', data.pictures);
        },
        // <-- api -->
        loadNew() {
            this.showOverlay(true);
            // <-- record -->
            // this.$nextTick(() => {
            this.exCategoryId = null;
            this.exTypeId = null;
            this.exTypes = [];
            this.languages = [];
            this.$set(this, 'record', this.__clone(this.recordDefault));
            this.$set(this, 'recordClone', this.__clone(this.record));
            let id = 0;
            // <-- api -->
            const config = {
                headers: this.__getHeaders()
            };
            // <-- axios -->
            this.axios.get(this.uri.uri1 + `/getForeignDataActive`, config)
                .then((response) => {
                    this.$set(this, 'data', response.data);
                    // <-- picture -->
                    let recordPictures = {
                        pictures_order: this.record.pictures_order,
                        pictures: this.record.pictures
                    };
                    this.$nextTick(function () {
                        this.pictureManagerBus.$emit('recordLoad', recordPictures);
                    });
                    // <--  -->
                    this.$bvModal.show(this.$options.name);
                    this.showOverlay(false);
                })
                .catch((error) => {
                    // on error executed
                });
        },
        loadEdit() {
            this.showOverlay(true);
            // <-- api -->
            const config = {
                headers: this.__getHeaders()
            };
            // <-- axios -->
            this.axios.get(this.uri.main + `/${this.recordId}`, config)
                .then((response) => {
                    // <-- data -->
                    this.$set(this, 'data', response.data.data);
                    // // <-- record -->
                    // let recordDefault = this.__clone(this.recordDefault);

                    let record = response.data.record;
                    this.languageId = record.content.isGuidedTour === false ? 0 : this.languageId;

                    // this.__mergeObjectDeep(recordDefault, record);
                    this.$set(this, 'record', record);
                    this.$set(this, 'recordClone', this.__clone(this.record));

                    this.$bvModal.show(this.$options.name);
                    // <-- pictures -->
                    let recordPictures = {
                        pictures_order: this.record.pictures_order,
                        pictures: this.record.pictures
                    };
                    this.$nextTick(function () {
                        this.pictureManagerBus.$emit('recordLoad', recordPictures)
                    });
                    // <--  -->
                    this.showOverlay(false);
                })
                .catch((error) => {
                    console.log("error:", error.data);
                });
        },
        // <-- form -->
        formSave(bvModalEvent) {
            bvModalEvent.preventDefault();
            // <--  -->
            this.tabValidity.check = true;
            let validity = true;
            // <-- check -->
            this.$nextTick(() => {
                Object.keys(this.tabValidity).forEach(key => {
                    if (key !== 'check' && !this.tabValidity[key]) {
                        validity = false;
                    }
                });
                // <-- save -->
                if (validity) {
                    if (this.record.id > 0) {
                        this.baseUpdateFormData();
                        return;
                    }
                    this.baseInsertFormData();
                }
            });
        },
        // <-- base -->
        baseInsertFormData() {
            this.showOverlay(true);
            // <-- clear errors -->
            this.resetErrors();
            // <-- api -->
            const config = {
                headers: this.__getFormDataHeaders(false)
            };
            // <-- form data -->
            let formData = new FormData();
            // <-- prepare files for axios -->
            if (this.record.pictures !== undefined) {
                this.record.pictures.forEach((picture, index) => {
                    formData.append('division', this.division);
                    formData.append('files[' + index + ']', picture.file);
                    formData.append('keys[' + index + ']', picture.key);
                });
            }
            // <-- prepare data for axios -->
            formData.append('record', JSON.stringify(this.record));
            // <-- axios -->
            // this.axios.post(this.uri.uri1 + '/form_data_insert', formData, config)
            this.axios.post(this.uri.main, formData, config)
                .then((response) => {
                    this.hideModal(response.data);
                    this.showOverlay(false);
                })
                .catch((error) => {
                    error = error.response.data;
                    this.errors.message = error;
                    this.showOverlay(false);
                })
                .finally(function () {
                    // always executed
                });
        },
        baseUpdateFormData() {
            this.showOverlay(true);
            // <-- clear errors -->
            this.resetErrors();
            // <-- api -->
            const config = {
                headers: this.__getFormDataHeaders(false)
            };
            // <-- form data -->
            let formData = new FormData();
            // <-- prepare files for axios -->
            if (this.record.pictures !== undefined) {
                this.record.pictures.forEach((picture, index) => {
                    formData.append('division', this.division);
                    formData.append('files[' + index + ']', picture.file);
                    formData.append('keys[' + index + ']', picture.key);
                });
            }
            // <-- prepare data for axios -->
            formData.append('record', JSON.stringify(this.record));
            // <-- axios -->
            // this.axios.post(this.uri.uri1 + '/form_data_insert', formData, config)
            this.axios.post(this.uri.uri1 + '/form_data_update' + `/${this.record.id}`, formData, config)
                .then((response) => {
                    this.hideModal(response.data);
                    this.showOverlay(false);
                })
                .catch((error) => {
                    error = error.response.data;
                    this.errors.message = error;
                    this.showOverlay(false);
                })
                .finally(function () {
                    // always executed
                });
        },
    },
    computed: {
        title: {
            get() {
                let record = this.record;
                if (this.recordId <= 0 || record === null || record.meta === undefined || record.meta.name === undefined) {
                    return this.$t('form.add') + ': ' + this.$tc(this.localeKey, 0).toLowerCase();
                }
                // <-- name -->
                let name = record.meta.name;
                name = this.__isNull(record.locale[this.$i18n.locale].name) ? name : record.locale[this.$i18n.locale].name;
                // <--  -->
                return this.$tc(this.localeKey, 0) + ': ' + name;
            },
        },
        // <-- data -->
        getOwnershipTypes() {
            let array = [];
            // <-- check data -->
            if (this.__isNull(this.data) || this.__isNull(this.data.ownershipTypes)) {
                return array;
            }
            // <--  -->
            let items = this.data.ownershipTypes;
            let text = null;
            items.forEach(item => {
                text = `(${item.meta.name})`;
                // <-- locale -->
                if (!this.__isNull(item.locale) && !this.__isNull(item.locale.name)) {
                    text = item.locale.name;
                }
                // <-- push -->
                array.push({
                    text: text,
                    value: item.id,
                    disabled: item.id === 3 // <-- disabled physical person -->
                });
            });
            // <-- add placeholder on top -->
            array.unshift({
                text: this.$t('form.choose_placeholder'),
                value: null
            });
            // <--  -->
            return array;
        },
        getOwnerVisible() {
            let visible = false;
            let visibleLegalEntity = false;
            let visiblePhysicalPerson = false;
            // <--  -->
            switch (this.record.ownership_type_id) {
                case 2:     // <-- legal entity -->
                    visible = true;
                    visibleLegalEntity = true;
                    visiblePhysicalPerson = false;
                    break;
                case 3:     // <-- physical person -->
                    visible = true;
                    visibleLegalEntity = false;
                    visiblePhysicalPerson = true;
                    break;
            }
            // <--  -->
            return {
                visible: visible,
                visibleLegalEntity: visibleLegalEntity,
                visiblePhysicalPerson: visiblePhysicalPerson
            };
        },
        getOrganisations() {
            let array = [
                {
                    text: this.$t('form.choose_placeholder'),
                    value: null
                }
            ];
            // <-- check data -->
            if (this.__isNull(this.data) || this.__isNull(this.data.organisations)) {
                return array;
            }
            // <--  -->
            let items = this.data.organisations;
            items.forEach(item => {
                array.push({
                    text: (this.__isNull(item.name) || this.__isNull(item.name.name) ? item.name.full_name : item.name.name),
                    value: item.id,
                    taxpayer: item.taxpayer
                });
            });
            // <--  -->
            return array;
        },
        getAddress() {
            // <-- new record -->
            if (this.record.id === undefined) {
                return this.record.address;
            }

            // <-- edit record -->
            let record = this.record;
            let address = this.__clone(this.recordDefault.address);

            address.state_id = this.__isNull(record.post_office) ? record.address.state_id : record.post_office.state_id;
            address.postOffice_id = record.post_office_id;
            address.region_id = record.region_id;
            address.street = record.address.street;
            address.full = record.address.full;
            // <--  -->
            this.$set(this.record, 'address', address);
            return this.record.address;
        },
        // <-- languageTour -->
        getLanguages() {
            let array = [];
            // <-- check data -->
            if (this.__isNull(this.data) || this.__isNull(this.data.languages)) {
                return [
                    {
                        text: this.$t('form.choose_placeholder'),
                        value: {id: null, text: null}
                    }
                ];
            }
            // <--  -->
            let items = this.data.languages;
            items = typeof items === 'object' ? Object.values(items) : items;

            let text = null;
            items.forEach(item => {
                text = item.language;
                // <-- push -->
                array.push({
                    text: text,
                    value: item.id
                });
            });
            // <-- sort -->
            // array = array.sort(function (a, b) {
            //     let fa = a.text.toLowerCase(),
            //         fb = b.text.toLowerCase();
            //
            //     if (fa < fb) {
            //         return -1;
            //     }
            //     if (fa > fb) {
            //         return 1;
            //     }
            //     return 0;
            // });
            // <-- add placeholder on top -->

            // array.unshift({
            //     text: this.$t('adventure.no_guided_tour'),
            //     value: 0,
            // });
            array.unshift({
                text: this.$t('form.choose_placeholder'),
                value: null
            });
            // <--  -->
            return array;
        },
        getLanguageTourIdsSelected() {
            let array = [];
            this.record.content.languageTourIds.forEach(id => {
                this.getLanguages.every(v => {
                    if (v.value === id) {
                        array.push(v);
                        return false;
                    }
                    return true;
                });
            });
            return array;
        },
        // <-- validity: tab basic -->
        nameValidity() {
            if (this.tabValidity === undefined || this.tabValidity.check === false) {
                return null;
            }
            // <--  -->
            let validity = !this.__isNull(this.record.meta.name);
            return validity ? null : false;
        },
        ownershipTypeIdValidity() {
            if (this.tabValidity === undefined || this.tabValidity.check === false) {
                return null;
            }
            // <--  -->
            let validity = this.record.ownership_type_id > 0;
            return validity ? null : false;
        },
        organisationIdValidity() {
            if (!this.getOwnerVisible.visibleLegalEntity || this.tabValidity === undefined || this.tabValidity.check === false) {
                return null;
            }
            // <--  -->
            let validity = this.record.organisation_id > 0;
            return validity ? null : false;
        },
        languageTourIdsValidity() {
            if (this.tabValidity === undefined || this.tabValidity.check === false) {
                return null;
            }
            // <--  -->
            let validity = this.record.content.isGuidedTour === false || this.record.content.languageTourIds.length > 0;
            return validity ? null : false;
        },
        urlValidity() {
            if (this.validity === undefined || this.validity.check === false) {
                return null;
            }
            // <--  -->
            if (this.__isNull(this.record.extended.webpage) || this.$refs['ref-webpage'] === undefined) {
                return null;
            }
            // <--  -->
            let validity = this.$refs['ref-webpage'].checkValidity();
            return validity ? null : validity;
        },
        tabBasicValidity() {
            let tabValidity = true;
            tabValidity = tabValidity === false || this.nameValidity === false ? false : tabValidity;
            tabValidity = tabValidity === false || this.ownershipTypeIdValidity === false ? false : tabValidity;
            tabValidity = tabValidity === false || this.organisationIdValidity === false ? false : tabValidity;
            tabValidity = tabValidity === false || this.languageTourIdsValidity === false ? false : tabValidity;
            tabValidity = tabValidity === false || this.urlValidity === false ? false : tabValidity;
            // <--  -->
            this.$set(this.tabValidity, 'tabBasic', tabValidity);
            return tabValidity
        },
        // <-- validity: location tab -->
        latitudeValidity() {
            if (this.tabValidity === undefined || this.tabValidity.check === false) {
                return null;
            }
            // <--  -->
            let validity = !this.__isNull(this.record.content.coordinates.latitude);
            return validity ? null : false;
        },
        longitudeValidity() {
            if (this.tabValidity === undefined || this.tabValidity.check === false) {
                return null;
            }
            // <--  -->
            let validity = !this.__isNull(this.record.content.coordinates.longitude);
            return validity ? null : false;
        },
        tabLocationValidity() {
            let tabValidity = true;
            // <--  -->
            tabValidity = tabValidity === false || this.latitudeValidity === false ? false : tabValidity;
            tabValidity = tabValidity === false || this.longitudeValidity === false ? false : tabValidity;
            // <--  -->
            this.$set(this.tabValidity, 'tab_location', tabValidity);
            return tabValidity ? null : false;
        },
    },
    filters: {},
    watch: {
        'languageId':
            {
                handler: function (value) {
                    this.addSelectedLanguage(value);
                    if (value !== 0) {
                        this.$nextTick(() => {
                            this.languageId = null;
                        });
                    }
                },
                deep: true
            },
    }
}
</script>

<style scoped>
.remove:hover {
    color: yellow;
    background: red;
    cursor: not-allowed;
    opacity: 40%;
}
</style>
